import stringSimilarity from 'string-similarity';

import { parseDayMonthYear } from 'helpers/date';
import { getCurrentDate } from 'helpers/date';
import { streetTypeDict } from 'helpers/streetType';
import { 
  parseAddress, 
  getMunicipalityFromZipCode 
} from 'services';

const sexIdentifier = {
  F: 'M', 
  M: 'V'  
};

export const createTemplateP2P = async (buyer, seller, vehicle, transaction) => {
  const buyerParsedAddress = await parseAddress(buyer?.attributes?.address);
  const buyerMatchResults = stringSimilarity.findBestMatch(
    buyerParsedAddress.streettype?.toUpperCase() || '',
    Object.keys(streetTypeDict),
  );
  const buyerBestStreetTypeMatch = buyerMatchResults?.bestMatch?.target;
  const buyerMunicipality = await getMunicipalityFromZipCode(buyer?.attributes?.zipCode);

  const sellerParsedAddress = await parseAddress(seller?.attributes?.address);
  const sellerMatchResults = stringSimilarity.findBestMatch(
    sellerParsedAddress.streettype?.toUpperCase() || '',
    Object.keys(streetTypeDict),
  );
  const sellerBestStreetTypeMatch = sellerMatchResults?.bestMatch?.target;
  const sellerMunicipality = await getMunicipalityFromZipCode(seller?.attributes?.zipCode);

    return `
    <?xml version="1.0" encoding="utf-8"?>
    <FORMATO_GA FechaCreacion="${getCurrentDate()}" Plataforma="OEGAM">
      <CABECERA>
        <DATOS_GESTORIA>
          <NIF>51922434A</NIF>
          <NOMBRE>CESAR JAVIER</NOMBRE>
          <PROFESIONAL>3054</PROFESIONAL>
          <PROVINCIA>MA</PROVINCIA>
        </DATOS_GESTORIA>
      </CABECERA>
      <TRANSMISION Version="1.0">
        <TIPO_TRANSFERENCIA>1</TIPO_TRANSFERENCIA>
        <NOTIFICACION_PREVIA>NO</NOTIFICACION_PREVIA>
        <NUMERO_EXPEDIENTE>${transaction.data.id}</NUMERO_EXPEDIENTE>
        <NUMERO_DOCUMENTO></NUMERO_DOCUMENTO>
        <NUMERO_PROFESIONAL>5853</NUMERO_PROFESIONAL>
        <FECHA_CREACION>${parseDayMonthYear(transaction.data.attributes.createdAt)}</FECHA_CREACION>
        <FECHA_PRESENTACION>${getCurrentDate()}</FECHA_PRESENTACION>
        <MATRICULA>${vehicle?.attributes?.plate || ''}</MATRICULA>
        <JEFATURA>MA</JEFATURA>
        <SUCURSAL></SUCURSAL>
        <IMPRESION_PERMISO_CIRCULACION>si</IMPRESION_PERMISO_CIRCULACION>
        <DATOS_TRANSMITENTE>
          <DNI_TRANSMITENTE>${seller?.attributes?.dni || ''}</DNI_TRANSMITENTE>
          <FECHA_NACIMIENTO_TRANSMITENTE>${(seller?.attributes?.birthDate ? parseDayMonthYear(seller?.attributes?.birthDate) : '') }</FECHA_NACIMIENTO_TRANSMITENTE>
          <APELLIDO1_RAZON_SOCIAL_TRANSMITENTE>${seller?.attributes?.firstSurname || ''}</APELLIDO1_RAZON_SOCIAL_TRANSMITENTE>
          <APELLIDO2_TRANSMITENTE>${seller?.attributes?.secondSurname || ''}</APELLIDO2_TRANSMITENTE>
          <NOMBRE_TRANSMITENTE>${seller?.attributes?.name || ''}</NOMBRE_TRANSMITENTE>
          <SEXO_TRANSMITENTE>${sexIdentifier[seller?.attributes?.sex] || ''}</SEXO_TRANSMITENTE>
          <EPIGRAFE_TRANSMITENTE></EPIGRAFE_TRANSMITENTE>
          <AUTONOMO_TRANSMITENTE></AUTONOMO_TRANSMITENTE>
          <COTITULARES_TRANSMITENTE></COTITULARES_TRANSMITENTE>
          <SIGLAS_DIRECCION_TRANSMITENTE>${streetTypeDict[sellerBestStreetTypeMatch] || ''}</SIGLAS_DIRECCION_TRANSMITENTE>
          <NOMBRE_VIA_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.streetname || ''}</NOMBRE_VIA_DIRECCION_TRANSMITENTE>
          <NUMERO_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.streetnumber || ''}</NUMERO_DIRECCION_TRANSMITENTE>
          <PISO_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.floor || ''}</PISO_DIRECCION_TRANSMITENTE>
          <PUERTA_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.door || ''}</PUERTA_DIRECCION_TRANSMITENTE>
          <MUNICIPIO_TRANSMITENTE>${sellerMunicipality.municipality || ''}</MUNICIPIO_TRANSMITENTE>
          <PUEBLO_TRANSMITENTE>${seller?.attributes?.city || ''}</PUEBLO_TRANSMITENTE>
          <PROVINCIA_TRANSMITENTE>${seller?.attributes?.province || ''}</PROVINCIA_TRANSMITENTE>
          <CP_TRANSMITENTE>${seller?.attributes?.zipCode || ''}</CP_TRANSMITENTE>
          <PAIS_TRANSMITENTE>ESP</PAIS_TRANSMITENTE>
          <TELEFONO_TRANSMITENTE>${seller?.attributes?.phone || ''}</TELEFONO_TRANSMITENTE>
          <NACIONALIDAD_TRANSMITENTE></NACIONALIDAD_TRANSMITENTE>
          <FECHA_CADUCIDAD_NIF_TRANSMITENTE>${(seller?.attributes?.dniExpirationDate ? parseDayMonthYear(seller?.attributes?.dniExpirationDate) : '')}</FECHA_CADUCIDAD_NIF_TRANSMITENTE>
        </DATOS_TRANSMITENTE>
        <DATOS_ADQUIRIENTE>
          <DNI_ADQUIRIENTE>${buyer?.attributes?.dni || ''}</DNI_ADQUIRIENTE>
          <FECHA_NACIMIENTO_ADQUIRIENTE>${(buyer?.attributes?.birthDate ? parseDayMonthYear(buyer?.attributes?.birthDate) : '')}</FECHA_NACIMIENTO_ADQUIRIENTE>
          <APELLIDO1_RAZON_SOCIAL_ADQUIRIENTE>${buyer?.attributes?.firstSurname || ''}</APELLIDO1_RAZON_SOCIAL_ADQUIRIENTE>
          <APELLIDO2_ADQUIRIENTE>${buyer?.attributes?.secondSurname || ''}</APELLIDO2_ADQUIRIENTE>
          <NOMBRE_ADQUIRIENTE>${buyer?.attributes?.name || ''}</NOMBRE_ADQUIRIENTE>
          <SEXO_ADQUIRIENTE>${sexIdentifier[buyer?.attributes?.sex] || ''}</SEXO_ADQUIRIENTE>
          <AUTONOMO_ADQUIRIENTE></AUTONOMO_ADQUIRIENTE>
          <CODIGO_IAE_ADQUIRIENTE></CODIGO_IAE_ADQUIRIENTE>
          <ESCOMPRAVENTA></ESCOMPRAVENTA>
          <CAMBIO_DOMICILIO_ADQUIRIENTE></CAMBIO_DOMICILIO_ADQUIRIENTE>
          <SIGLAS_DIRECCION_ADQUIRIENTE>${streetTypeDict[buyerBestStreetTypeMatch] || ''}</SIGLAS_DIRECCION_ADQUIRIENTE>
          <NOMBRE_VIA_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.streetname || ''}</NOMBRE_VIA_DIRECCION_ADQUIRIENTE>
          <NUMERO_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.streetnumber || ''}</NUMERO_DIRECCION_ADQUIRIENTE>
          <PISO_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.floor || ''}</PISO_DIRECCION_ADQUIRIENTE>
          <PUERTA_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.door || ''}</PUERTA_DIRECCION_ADQUIRIENTE>
          <MUNICIPIO_ADQUIRIENTE>${buyerMunicipality.municipality || ''}</MUNICIPIO_ADQUIRIENTE>
          <PUEBLO_ADQUIRIENTE>${buyer?.attributes?.city || ''}</PUEBLO_ADQUIRIENTE>
          <PROVINCIA_ADQUIRIENTE>${buyer?.attributes?.province || ''}</PROVINCIA_ADQUIRIENTE>
          <CP_ADQUIRIENTE>${buyer?.attributes?.zipCode || ''}</CP_ADQUIRIENTE>
          <PAIS_ADQUIRIENTE>ESP</PAIS_ADQUIRIENTE>
          <TELEFONO_ADQUIRIENTE>${buyer?.attributes?.phone || ''}</TELEFONO_ADQUIRIENTE>
          <FECHA_CADUCIDAD_NIF_ADQUIRIENTE>${(buyer?.attributes?.dniExpirationDate ? parseDayMonthYear(buyer?.attributes?.dniExpirationDate) : '')}</FECHA_CADUCIDAD_NIF_ADQUIRIENTE>
          <EXENTO_CADU_DOI_ADQUIRIENTE></EXENTO_CADU_DOI_ADQUIRIENTE>
          <NACIONALIDAD_ADQUIRIENTE></NACIONALIDAD_ADQUIRIENTE>
        </DATOS_ADQUIRIENTE>
        <DATOS_PRESENTADOR>
          <DNI_PRESENTADOR>51922434A</DNI_PRESENTADOR>
          <APELLIDO1_RAZON_SOCIAL_PRESENTADOR>RUEDA</APELLIDO1_RAZON_SOCIAL_PRESENTADOR>
          <APELLIDO2_PRESENTADOR>GONZALEZ</APELLIDO2_PRESENTADOR>
          <NOMBRE_PRESENTADOR>CESAR JAVIER</NOMBRE_PRESENTADOR>
          <TELEFONO_PRESENTADOR>917851707</TELEFONO_PRESENTADOR>
          <SIGLAS_DIRECCION_PRESENTADOR>41</SIGLAS_DIRECCION_PRESENTADOR>
          <NOMBRE_VIA_DIRECCION_PRESENTADOR>MARTINEZ DE LA RIVA</NOMBRE_VIA_DIRECCION_PRESENTADOR>
          <NUMERO_DIRECCION_PRESENTADOR>114</NUMERO_DIRECCION_PRESENTADOR>
          <LETRA_DIRECCION_PRESENTADOR></LETRA_DIRECCION_PRESENTADOR>
          <ESCALERA_DIRECCION_PRESENTADOR></ESCALERA_DIRECCION_PRESENTADOR>
          <PISO_DIRECCION_PRESENTADOR>PB</PISO_DIRECCION_PRESENTADOR>
          <PUERTA_DIRECCION_PRESENTADOR>A</PUERTA_DIRECCION_PRESENTADOR>
          <PROVINCIA_PRESENTADOR>M</PROVINCIA_PRESENTADOR>
          <MUNICIPIO_PRESENTADOR>MADRID</MUNICIPIO_PRESENTADOR>
          <CP_PRESENTADOR>28018</CP_PRESENTADOR>
          <SEXO_PRESENTADOR>V</SEXO_PRESENTADOR>
          <FECHA_NACIMIENTO_PRESENTADOR>15/01/1970</FECHA_NACIMIENTO_PRESENTADOR>
        </DATOS_PRESENTADOR>
        <DATOS_VEHICULO>
          <MATRICULA>${vehicle?.attributes?.plate || ''}</MATRICULA>
          <FECHA_MATRICULACION>${(vehicle?.attributes?.registrationDate ? parseDayMonthYear(vehicle?.attributes?.registrationDate) : '')}</FECHA_MATRICULACION>
          <FECHA_PRIMERA_MATRICULACION></FECHA_PRIMERA_MATRICULACION>
          <MARCA>${vehicle?.attributes?.brand || ''}</MARCA>
          <MODELO>${vehicle?.attributes?.model || ''}</MODELO>
          <NUMERO_BASTIDOR>${vehicle?.attributes?.vin || ''}</NUMERO_BASTIDOR>
          <CILINDRADA>${vehicle?.attributes?.cc || ''}</CILINDRADA>
          <NUMERO_CILINDROS></NUMERO_CILINDROS>
          <PLAZAS></PLAZAS>
          <MODO_ADJUDICACION></MODO_ADJUDICACION>
          <SERVICIO_DESTINA>${vehicle?.attributes?.serviceType || ''}</SERVICIO_DESTINA>
          <CLASE_VEHICULO></CLASE_VEHICULO>
          <TIPO_VEHICULO>40</TIPO_VEHICULO>
          <CHECK_CADUCIDAD_ITV>NO</CHECK_CADUCIDAD_ITV>
        </DATOS_VEHICULO>
      </TRANSMISION>
    </FORMATO_GA>
  `.trim();
  }
