import stringSimilarity from 'string-similarity';

import { parseDayMonthYear } from 'helpers/date';
import { getCurrentDate } from 'helpers/date';
import { streetTypeDict } from 'helpers/streetType';
import { 
  getFullInfoFromAddress, 
  parseAddress, 
  getMunicipalityFromZipCode 
} from 'services';

const sexIdentifier = {
  F: 'M', 
  M: 'V'  
};

export const createTemplateC2P = async (buyer, sellerCompany, vehicle, transaction) => {
  const buyerParsedAddress = await parseAddress(buyer?.attributes?.address);
  const buyerMatchResults = stringSimilarity.findBestMatch(
    buyerParsedAddress.streettype?.toUpperCase() || '',
    Object.keys(streetTypeDict),
  );
  const buyerBestStreetTypeMatch = buyerMatchResults?.bestMatch?.target;
  const buyerMunicipality = await getMunicipalityFromZipCode(buyer?.attributes?.zipCode);

  const sellerFullAddress = await getFullInfoFromAddress(sellerCompany?.attributes?.fiscalAddressAddress);
  const sellerParsedAddress = await parseAddress(sellerCompany?.attributes?.fiscalAddressAddress);
  const sellerMatchResults = stringSimilarity.findBestMatch(
    sellerParsedAddress.streettype?.toUpperCase() || '',
    Object.keys(streetTypeDict),
  );
  const sellerBestStreetTypeMatch = sellerMatchResults?.bestMatch?.target;
  const sellerMunicipality = await getMunicipalityFromZipCode(sellerCompany?.attributes?.fiscalAddressZipCode);

  const sellerRepresentativeFullAddress = await getFullInfoFromAddress(sellerCompany?.attributes?.representative?.data?.attributes?.address);
  const sellerRepresentativeParsedAddress = await parseAddress(sellerCompany?.attributes?.representative?.data?.attributes?.address);
  const sellerRepresentativeMatchResults = stringSimilarity.findBestMatch(
    sellerRepresentativeParsedAddress.streettype?.toUpperCase() || '',
    Object.keys(streetTypeDict),
  );
  const sellerRepresentativeBestStreetTypeMatch = sellerRepresentativeMatchResults?.bestMatch?.target;
  const sellerRepresentativeMunicipality = await getMunicipalityFromZipCode(sellerCompany?.attributes?.representative?.data.attributes?.zipCode);

  return `
    <?xml version="1.0" encoding="utf-8"?>
    <FORMATO_GA FechaCreacion="12/06/2024" Plataforma="OEGAM">
      <CABECERA>
        <DATOS_GESTORIA>
          <NIF>51922434A</NIF>
          <NOMBRE>CESAR JAVIER</NOMBRE>
          <PROFESIONAL>3054</PROFESIONAL>
          <PROVINCIA>MA</PROVINCIA>
        </DATOS_GESTORIA>
      </CABECERA>
      <TRANSMISION Version="1.0" ProcesarTransmision="1" Procesar620="0">
        <TIPO_TRANSFERENCIA>1</TIPO_TRANSFERENCIA>
        <NOTIFICACION_PREVIA>NO</NOTIFICACION_PREVIA>
        <NUMERO_EXPEDIENTE>${transaction.data.id}</NUMERO_EXPEDIENTE>
        <NUMERO_DOCUMENTO></NUMERO_DOCUMENTO>
        <NUMERO_PROFESIONAL>5853</NUMERO_PROFESIONAL>
        <FECHA_CREACION>${parseDayMonthYear(transaction.data.attributes.createdAt)}</FECHA_CREACION>
        <FECHA_PRESENTACION>${getCurrentDate()}</FECHA_PRESENTACION>
        <MATRICULA>${vehicle?.attributes?.plate || ''}</MATRICULA>
        <FECHA_DEVOLUCION></FECHA_DEVOLUCION>
        <JEFATURA>MA</JEFATURA>
        <SUCURSAL></SUCURSAL>
        <TIPO_TASA></TIPO_TASA>
        <TASA></TASA>
        <TASA_INFORME></TASA_INFORME>
        <TASA_CAMBIO_SERVICIO></TASA_CAMBIO_SERVICIO>
        <PROCEDENCIA></PROCEDENCIA>
        <IMPRESION_PERMISO_CIRCULACION>No</IMPRESION_PERMISO_CIRCULACION>
        <DATOS_TRANSMITENTE>
          <DNI_TRANSMITENTE>${sellerCompany?.attributes?.nif || ''}</DNI_TRANSMITENTE>
          <RAZON_SOCIAL_TRANSMITENTE>${sellerCompany?.attributes?.legalName || ''}</RAZON_SOCIAL_TRANSMITENTE>
          <APELLIDO1_RAZON_SOCIAL_TRANSMITENTE>${sellerCompany?.attributes?.legalName || ''}</APELLIDO1_RAZON_SOCIAL_TRANSMITENTE>
          <APELLIDO2_TRANSMITENTE></APELLIDO2_TRANSMITENTE>
          <NOMBRE_TRANSMITENTE></NOMBRE_TRANSMITENTE>
          <SEXO_TRANSMITENTE>X</SEXO_TRANSMITENTE>
          <EPIGRAFE_TRANSMITENTE></EPIGRAFE_TRANSMITENTE>
          <FECHA_NACIMIENTO_TRANSMITENTE></FECHA_NACIMIENTO_TRANSMITENTE>
          <ESCOMPRAVENTA>NO</ESCOMPRAVENTA>
          <COTITULARES_TRANSMITENTE>0</COTITULARES_TRANSMITENTE>
          <SIGLAS_DIRECCION_TRANSMITENTE>>${streetTypeDict[sellerBestStreetTypeMatch] || ''}</SIGLAS_DIRECCION_TRANSMITENTE>
          <NOMBRE_VIA_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.streetname || ''}</NOMBRE_VIA_DIRECCION_TRANSMITENTE>
          <NUMERO_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.streetnumber || ''}</NUMERO_DIRECCION_TRANSMITENTE>
          <KM_DIRECCION_TRANSMITENTE></KM_DIRECCION_TRANSMITENTE>
          <HECTOMETRO_DIRECCION_TRANSMITENTE></HECTOMETRO_DIRECCION_TRANSMITENTE>
          <LETRA_DIRECCION_TRANSMITENTE></LETRA_DIRECCION_TRANSMITENTE>
          <ESCALERA_DIRECCION_TRANSMITENTE></ESCALERA_DIRECCION_TRANSMITENTE>
          <PISO_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.floor || ''}</PISO_DIRECCION_TRANSMITENTE>
          <PUERTA_DIRECCION_TRANSMITENTE>${sellerParsedAddress?.door || ''}</PUERTA_DIRECCION_TRANSMITENTE>
          <BLOQUE_DIRECCION_TRANSMITENTE></BLOQUE_DIRECCION_TRANSMITENTE>
          <MUNICIPIO_TRANSMITENTE>${sellerMunicipality.municipality || ''}</MUNICIPIO_TRANSMITENTE>
          <PUEBLO_TRANSMITENTE>${sellerFullAddress.city}</PUEBLO_TRANSMITENTE>
          <PROVINCIA_TRANSMITENTE>${sellerCompany?.attributes?.fiscalAddressProvince || ''}</PROVINCIA_TRANSMITENTE>
          <CP_TRANSMITENTE>${sellerCompany?.attributes?.fiscalAddressZipCode || ''}</CP_TRANSMITENTE>
          <PAIS_TRANSMITENTE>ESP</PAIS_TRANSMITENTE>
          <TELEFONO_TRANSMITENTE></TELEFONO_TRANSMITENTE>
          <FAX_TRANSMITENTE></FAX_TRANSMITENTE>
          <ANAGRAMA_TRANS></ANAGRAMA_TRANS>
          <DOI_SUSTITUTIVO_TRANSMITENTE></DOI_SUSTITUTIVO_TRANSMITENTE>
          <FECHA_CADUCIDAD_NIF_TRANSMITENTE></FECHA_CADUCIDAD_NIF_TRANSMITENTE>
          <EXENTO_CADU_DOI_TRANSMITENTE></EXENTO_CADU_DOI_TRANSMITENTE>
          <ESTADO_CIVIL_TRANSMITENTE></ESTADO_CIVIL_TRANSMITENTE>
          <NACIONALIDAD_TRANSMITENTE></NACIONALIDAD_TRANSMITENTE>
          <AUTONOMO_TRANSMITENTE>SI</AUTONOMO_TRANSMITENTE>
          <IAE_TRANSMITENTE></IAE_TRANSMITENTE>
        </DATOS_TRANSMITENTE>
        <DATOS_REPRESENTANTE_TRANSMITENTE>
          <DNI_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.dni || ''}</DNI_REP_TRANSMITENTE>
          <APELLIDO1_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.firstSurname || ''}</APELLIDO1_REP_TRANSMITENTE>
          <APELLIDO2_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.secondSurname || ''}</APELLIDO2_REP_TRANSMITENTE>
          <NOMBRE_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.name || ''}</NOMBRE_REP_TRANSMITENTE>
          <ACREDITACION_REP_TRANSMITENTE></ACREDITACION_REP_TRANSMITENTE>
          <DOI_SUSTITUTIVO_REP_TRANSMITENTE></DOI_SUSTITUTIVO_REP_TRANSMITENTE>
          <FECHA_CADU_DOI_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.dniExpirationDate || ''}</FECHA_CADU_DOI_REP_TRANSMITENTE>
          <EXENTO_CADU_DOI_REP_TRANSMITENTE>NO</EXENTO_CADU_DOI_REP_TRANSMITENTE>
          <ESTADO_CIVIL_REP_TRANSMITENTE></ESTADO_CIVIL_REP_TRANSMITENTE>
          <NACIONALIDAD_REP_TRANSMITENTE></NACIONALIDAD_REP_TRANSMITENTE>
          <SIGLAS_DIRECCION_REP_TRANSMITENTE>${streetTypeDict[sellerRepresentativeBestStreetTypeMatch] || ''}</SIGLAS_DIRECCION_REP_TRANSMITENTE>
          <NOMBRE_VIA_DIRECCION_REP_TRANSMITENTE>${sellerRepresentativeParsedAddress.streetname || ''}</NOMBRE_VIA_DIRECCION_REP_TRANSMITENTE>
          <NUMERO_DIRECCION_REP_TRANSMITENTE>${sellerRepresentativeFullAddress?.streetnumber || ''}</NUMERO_DIRECCION_REP_TRANSMITENTE>
          <PISO_DIRECCION_REP_TRANSMITENTE>${sellerRepresentativeParsedAddress.floor || ''}</PISO_DIRECCION_REP_TRANSMITENTE>
          <PUERTA_DIRECCION_REP_TRANSMITENTE>${sellerRepresentativeParsedAddress.door || ''}</PUERTA_DIRECCION_REP_TRANSMITENTE>
          <BLOQUE_DIRECCION_REP_TRANSMITENTE></BLOQUE_DIRECCION_REP_TRANSMITENTE>
          <MUNICIPIO_REP_TRANSMITENTE>${sellerRepresentativeMunicipality.municipality || ''}</MUNICIPIO_REP_TRANSMITENTE>
          <PUEBLO_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.city || ''}</PUEBLO_REP_TRANSMITENTE>
          <PROVINCIA_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.province || ''}</PROVINCIA_REP_TRANSMITENTE>
          <CP_REP_TRANSMITENTE>${sellerCompany?.attributes?.representative?.data.attributes?.zipCode || ''}</CP_REP_TRANSMITENTE>
          <PAIS_REP_TRANSMITENTE></PAIS_REP_TRANSMITENTE>
        </DATOS_REPRESENTANTE_TRANSMITENTE>
        <DATOS_ADQUIRIENTE>
          <DNI_ADQUIRIENTE>${buyer?.attributes?.dni || ''}</DNI_ADQUIRIENTE>
          <FECHA_NACIMIENTO_ADQUIRIENTE>${(buyer?.attributes?.birthDate ? parseDayMonthYear(buyer?.attributes?.birthDate) : '')}</FECHA_NACIMIENTO_ADQUIRIENTE>
          <RAZON_SOCIAL_ADQUIRIENTE></RAZON_SOCIAL_ADQUIRIENTE>
          <APELLIDO1_RAZON_SOCIAL_ADQUIRIENTE>${buyer?.attributes?.firstSurname || ''}</APELLIDO1_RAZON_SOCIAL_ADQUIRIENTE>
          <APELLIDO2_ADQUIRIENTE>${buyer?.attributes?.secondSurname || ''}</APELLIDO2_ADQUIRIENTE>
          <NOMBRE_ADQUIRIENTE>${buyer?.attributes?.name || ''}</NOMBRE_ADQUIRIENTE>
          <EPIGRAFE_ADQUIRIENTE></EPIGRAFE_ADQUIRIENTE>
          <SEXO_ADQUIRIENTE>${sexIdentifier[buyer?.attributes?.sex] || ''}</SEXO_ADQUIRIENTE>
          <ANAGRAMA_ADQUIRIENTE></ANAGRAMA_ADQUIRIENTE>
          <AUTONOMO_ADQUIRIENTE>NO</AUTONOMO_ADQUIRIENTE>
          <CODIGO_IAE_ADQUIRIENTE></CODIGO_IAE_ADQUIRIENTE>
          <ESCOMPRAVENTA>NO</ESCOMPRAVENTA>
          <COTITULARES_ADQUIRIENTE>0</COTITULARES_ADQUIRIENTE>
          <CAMBIO_DOMICILIO_ADQUIRIENTE>NO</CAMBIO_DOMICILIO_ADQUIRIENTE>
          <SIGLAS_DIRECCION_ADQUIRIENTE>${streetTypeDict[buyerBestStreetTypeMatch] || ''}</SIGLAS_DIRECCION_ADQUIRIENTE>
          <NOMBRE_VIA_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.streetname || ''}</NOMBRE_VIA_DIRECCION_ADQUIRIENTE>
          <NUMERO_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.streetnumber || ''}</NUMERO_DIRECCION_ADQUIRIENTE>
          <PISO_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.floor || ''}</PISO_DIRECCION_ADQUIRIENTE>
          <PUERTA_DIRECCION_ADQUIRIENTE>${buyerParsedAddress?.door || ''}</PUERTA_DIRECCION_ADQUIRIENTE>
          <MUNICIPIO_ADQUIRIENTE>${buyerMunicipality.municipality || ''}</MUNICIPIO_ADQUIRIENTE>
          <PUEBLO_ADQUIRIENTE>${buyer?.attributes?.city || ''}</PUEBLO_ADQUIRIENTE>
          <PROVINCIA_ADQUIRIENTE>${buyer?.attributes?.province || ''}</PROVINCIA_ADQUIRIENTE>
          <CP_ADQUIRIENTE>${buyer?.attributes?.zipCode || ''}</CP_ADQUIRIENTE>
          <PAIS_ADQUIRIENTE>ESP</PAIS_ADQUIRIENTE>
          <TELEFONO_ADQUIRIENTE>${buyer?.attributes?.phone || ''}</TELEFONO_ADQUIRIENTE>
          <FAX_ADQUIRIENTE></FAX_ADQUIRIENTE>
          <DOI_SUSTITUTIVO_ADQUIRIENTE></DOI_SUSTITUTIVO_ADQUIRIENTE>
          <FECHA_CADUCIDAD_NIF_ADQUIRIENTE>${(buyer?.attributes?.dniExpirationDate ? parseDayMonthYear(buyer?.attributes?.dniExpirationDate) : '')}</FECHA_CADUCIDAD_NIF_ADQUIRIENTE>
          <EXENTO_CADU_DOI_ADQUIRIENTE></EXENTO_CADU_DOI_ADQUIRIENTE>
          <ESTADO_CIVIL_ADQUIRIENTE></ESTADO_CIVIL_ADQUIRIENTE>
          <NACIONALIDAD_ADQUIRIENTE></NACIONALIDAD_ADQUIRIENTE>
        </DATOS_ADQUIRIENTE>
        <DATOS_VEHICULO>
          <MATRICULA>${vehicle?.attributes?.plate || ''}</MATRICULA>
          <FECHA_MATRICULACION>${(vehicle?.attributes?.registrationDate ? parseDayMonthYear(vehicle?.attributes?.registrationDate) : '')}</FECHA_MATRICULACION>
          <FECHA_PRIMERA_MATRICULACION></FECHA_PRIMERA_MATRICULACION>
          <PROVINCIA_PRIMERA_MATRICULACION></PROVINCIA_PRIMERA_MATRICULACION>
          <DIRECCION_VEHICULO>
          <SIGLAS_DIRECCION_VEHICULO></SIGLAS_DIRECCION_VEHICULO>
          <NOMBRE_VIA_DIRECCION_VEHICULO></NOMBRE_VIA_DIRECCION_VEHICULO>
          <NUMERO_DIRECCION_VEHICULO></NUMERO_DIRECCION_VEHICULO>
          <KM_DIRECCION_VEHICULO></KM_DIRECCION_VEHICULO>
          <HECTOMETRO_DIRECCION_VEHICULO></HECTOMETRO_DIRECCION_VEHICULO>
          <BLOQUE_DIRECCION_VEHICULO></BLOQUE_DIRECCION_VEHICULO>
          <LETRA_DIRECCION_VEHICULO></LETRA_DIRECCION_VEHICULO>
          <PISO_DIRECCION_VEHICULO></PISO_DIRECCION_VEHICULO>
          <PUERTA_DIRECCION_VEHICULO></PUERTA_DIRECCION_VEHICULO>
          <ESCALERA_DIRECCION_VEHICULO></ESCALERA_DIRECCION_VEHICULO>
          <MUNICIPIO_VEHICULO></MUNICIPIO_VEHICULO>
          <PUEBLO_VEHICULO></PUEBLO_VEHICULO>
          <PROVINCIA_VEHICULO></PROVINCIA_VEHICULO>
          <CP_VEHICULO></CP_VEHICULO>
          <PAIS_VEHICULO></PAIS_VEHICULO>
          </DIRECCION_VEHICULO>
          <AÑO_FABRICACION></AÑO_FABRICACION>
          <AÑOS_CIRCULACION></AÑOS_CIRCULACION>
          <ID_VEHICULO></ID_VEHICULO>
          <MARCA>${vehicle?.attributes?.brand || ''}</MARCA>
          <MODELO>${vehicle?.attributes?.model || ''}</MODELO>
          <NUMERO_BASTIDOR>${vehicle?.attributes?.vin || ''}</NUMERO_BASTIDOR>
          <CILINDRADA>${vehicle?.attributes?.cc || ''}</CILINDRADA>
          <POTENCIA></POTENCIA>
          <CARBURANTE></CARBURANTE>
          <NUMERO_CILINDROS></NUMERO_CILINDROS>
          <MASA></MASA>
          <TARA></TARA>
          <PLAZAS></PLAZAS>
          <MODO_ADJUDICACION></MODO_ADJUDICACION>
          <SERVICIO_DESTINA>${vehicle?.attributes?.serviceType || ''}</SERVICIO_DESTINA>
          <CAMBIO_SERVICIO>NO</CAMBIO_SERVICIO>
          <SERVICIO_DESTINA_NUEVO></SERVICIO_DESTINA_NUEVO>
          <TIPO_VEHICULO>40</TIPO_VEHICULO>
          <CLASE_VEHICULO></CLASE_VEHICULO>
          <CARACTERISTICAS_VEHICULO></CARACTERISTICAS_VEHICULO>
          <CODIGO_ITV_INDUSTRIA></CODIGO_ITV_INDUSTRIA>
          <FECHA_ITV></FECHA_ITV>
          <MOTIVO_ITV></MOTIVO_ITV>
          <FECHA_ULTIMA_ITV></FECHA_ULTIMA_ITV>
          <ESTACION_ITV></ESTACION_ITV>
          <CODIGO_ESTACION_ITV></CODIGO_ESTACION_ITV>
          <RESULTADO_ITV></RESULTADO_ITV>
          <PROVINCIA_ITV></PROVINCIA_ITV>
          <RENTING>NO</RENTING>
          <HISTORICO>NO</HISTORICO>
        </DATOS_VEHICULO>
        <DATOS_PRESENTADOR>
          <TELEFONO_PRESENTADOR>917851707</TELEFONO_PRESENTADOR>
          <SIGLAS_DIRECCION_PRESENTADOR>41</SIGLAS_DIRECCION_PRESENTADOR>
          <NOMBRE_VIA_DIRECCION_PRESENTADOR>MARTINEZ DE LA RIVA</NOMBRE_VIA_DIRECCION_PRESENTADOR>
          <NUMERO_DIRECCION_PRESENTADOR>114</NUMERO_DIRECCION_PRESENTADOR>
          <LETRA_DIRECCION_PRESENTADOR></LETRA_DIRECCION_PRESENTADOR>
          <ESCALERA_DIRECCION_PRESENTADOR></ESCALERA_DIRECCION_PRESENTADOR>
          <PISO_DIRECCION_PRESENTADOR>PB</PISO_DIRECCION_PRESENTADOR>
          <PUERTA_DIRECCION_PRESENTADOR>A</PUERTA_DIRECCION_PRESENTADOR>
          <PROVINCIA_PRESENTADOR>M</PROVINCIA_PRESENTADOR>
          <MUNICIPIO_PRESENTADOR>MADRID</MUNICIPIO_PRESENTADOR>
          <CP_PRESENTADOR>28018</CP_PRESENTADOR>
          <SEXO_PRESENTADOR>V</SEXO_PRESENTADOR>
          <FECHA_NACIMIENTO_PRESENTADOR>15/01/1970</FECHA_NACIMIENTO_PRESENTADOR>
        </DATOS_PRESENTADOR>
      </TRANSMISION>
    </FORMATO_GA>
`.trim();
}